<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		<a-row :gutter="24">
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
				<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.totalPages" :pageSize="pagination.perPage" show-less-items />
			</a-col>
		</a-row>

		<div v-if="!loadingData">

			<a-row :gutter="24">

				<a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24" v-for="clas in classes" :key="clas.uuid">
					<a-card>
						<a-row :gutter="24">
							<a-col :span="24">
								<div class="icon">
									<img id="classImg" width="100%" height="200px" :src="clas.iconUrl != null ? clas.iconUrl : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
								</div>
							</a-col>
						</a-row>
						<a-row :gutter="24">
							<a-col :span="24">
								<h6 class="mt-10 pt-4 pl-2">{{ clas.name }}</h6>
							</a-col>
						</a-row>
						<a-row :gutter="24">

							<a-col :span="24" :md="24" :lg="24" :xl="24">
								<a-avatar size="medium" :src="clas.creator != null && clas.creator.profilePicUrl ? clas.creator.profilePicUrl : ''" />&nbsp;&nbsp;
								{{ clas.creator != null ? `${clas.creator.firstName} ${clas.creator.lastName}` : 'Unknown user'}}
							</a-col>
						</a-row>

						<a-row :gutter="24" class="mt-10">
							<a-col :span="24" :md="12" :lg="12" :xl="12">
								<a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
								<small>{{ clas.slots + 1 - clas.membersCount }} Slots remain </small>
							</a-col>

							<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right">
								<h6 class="text-silabu pr-5">{{ clas.isPaid ? `${clas.joiningFee}/=` : 'FREE' }}</h6>
							</a-col>
						</a-row>
					</a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getClasses();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getClasses()
                },
            },
            deep: true,
        },
		created() {
			this.getClasses()
		},
		methods: {

			async getClasses() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/classes/web-list/?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
						this.pagination.totalPages = response.data.totalPages;
						this.pagination.total = response.data.total;
						this.pagination.currentPage = response.data.currentPage;
						this.currentPage = response.data.currentPage;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}
</style>