import Vue from 'vue'
import VueRouter from 'vue-router'


//Classes
import Classes from '../views/Classes/Index.vue'
import MyClasses from '../views/MyClasses/Index.vue'
import ViewMyClass from '../views/MyClasses/View.vue'
import LiveClass from '../views/MyClasses/Classroom/Index.vue'
import ViewMyClassQuiz from '../views/MyClasses/Quiz/View.vue'
import AddMyClassQuizQuestion from '../views/MyClasses/Quiz/AddQuestion.vue'
import EditMyClassQuizQuestion from '../views/MyClasses/Quiz/EditQuestion.vue'

//Notes && Past Papers
import Notes from '../views/MyClasses/Notes/Index.vue'
import PastPapers from '../views/MyClasses/PastPapers/Index.vue'


//Services
import PdfViewer from '../views/MyClasses/Services/PdfViewer.vue'
import VideoPlayer from '../views/MyClasses/Services/VideoPlayer.vue'


Vue.use(VueRouter)

const checkAuth = async function(to, from, next) {
	// console.log(!window.localStorage.getItem("user_token"));
	let token = window.localStorage.getItem("user_token")
	
	await window.localStorage.setItem("previousUrl", to.fullPath)
	
	if(!token || token == 'null') {	
	  	next('/sign-in')
	}else{
	  next();
	}
}

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
		beforeEnter: checkAuth,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/classes',
		name: 'Classes',
		layout: "dashboard",
		component: Classes,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes',
		name: 'My Classes',
		layout: "dashboard",
		component: MyClasses,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid',
		name: 'View Class',
		layout: "dashboard",
		component: ViewMyClass,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/live-class',
		name: 'Live Class',
		layout: "",
		component: LiveClass,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/quiz/:quizUuid',
		name: 'View Class Quiz',
		layout: "dashboard",
		component: ViewMyClassQuiz,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/quiz/:quizUuid/add-question',
		name: 'Add Question',
		layout: "dashboard",
		component: AddMyClassQuizQuestion,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/quiz/:quizUuid/edit-question/:questionUuid',
		name: 'Edit Question',
		layout: "dashboard",
		component: EditMyClassQuizQuestion,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/notes-directory/:directoryUuid',
		name: 'Notes',
		layout: "dashboard",
		component: Notes,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/past-papers-directory/:directoryUuid',
		name: 'Past Papers',
		layout: "dashboard",
		component: PastPapers,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/pdf-viewer',
		name: 'Pdf Viewer',
		layout: "",
		component: PdfViewer,
		beforeEnter: checkAuth,
	},
	{
		path: '/my-classes/view/:uuid/video-player',
		name: 'Video Player',
		layout: "",
		component: VideoPlayer,
		beforeEnter: checkAuth,
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/forgot-password',
		name: 'Forgot Password',
		component: () => import('../views/ForgotPassword.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
