<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		<a-row :gutter="24">
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
			</a-col>
		</a-row>

		<div v-if="!loadingData">

			<a-row :gutter="24">

				<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
					<a-card class="px-5">
						<a-row :gutter="24">
							<a-col :span="24" :md="12" :lg="12" :xl="12">
								<h4 class="py-5 mt-5">
									<a-avatar 
										:size="90" 
										:src="classDetails.iconUrl != null ? 
											classDetails.iconUrl : 
											'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" 
										alt=""/>
										&nbsp;&nbsp;
									{{ classDetails.name }}
								</h4>
							</a-col>

							<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right">
								<a-button 
									id="btn" 
									type="silabu" 
									class="pt-3 pb-3 text-align-center" 
									style="margin-top: 40px !important;"
									:href="`/#/my-classes/view/${this.$route.params.uuid}/live-class`"
									target="_blank"
									v-if="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid">
									<a-icon type="video-camera" theme="outlined" class="" style="font-size: 17px; margin-right: 7px; margin-top: 10px;" /> <span class="" style="padding-bottom: 10px !important;">Live Class</span>
								</a-button>
							</a-col>
						</a-row>
						
						<a-row :gutters="24">
							<a-col :span="24">
								<a-tabs v-model="activeTab" @change="onChangeActiveTab">
									<a-tab-pane key="classroom" tab="Classroom">
										Classroom
									</a-tab-pane>
									<a-tab-pane key="schedule" tab="Schedule" force-render>Schedule</a-tab-pane>
									<a-tab-pane key="library" tab="Topic">
										<Topic 
											:classDetails="classDetails" 
											:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-tab-pane>
									<a-tab-pane key="notes" tab="Notes">
										<NotesDirectory 
											:classDetails="classDetails" 
											:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-tab-pane>
									<a-tab-pane key="past-papers" tab="Past Papers">
										<PastPapersDirectory 
											:classDetails="classDetails" 
											:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-tab-pane>
									<a-tab-pane key="quiz" tab="Quiz" class="bg-white">
										<Quiz 
											:classDetails="classDetails" 
											:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-tab-pane>
									<a-tab-pane key="assessment" tab="Assessment">Assessment</a-tab-pane>
									<a-tab-pane key="members" tab="Members">Members</a-tab-pane>
								</a-tabs>
							</a-col>
						</a-row>
					</a-card>
				</a-col>

			</a-row>
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>
	</div>
</template>



<script>
	import socketIO from "socket.io-client"
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
	import Quiz from './Quiz/Index';
	import NotesDirectory from './Notes/Directory';
	import PastPapersDirectory from './PastPapers/Directory';
	// import Classroom from './Classroom/Index';
	import Topic from './Topic/Index';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
			Quiz,
			NotesDirectory,
			PastPapersDirectory,
			// Classroom,
			Topic,
		},

		data() {
			return {

				loadingData: false,
				activeTab: null,
				classDetails: {},
				userDetails: {},

				socket: null,

				search: "",
				message: "Hello",
				api: null,
				room: "test romm",
				username: "rabie",
      		}
		},
		created() {
			this.initializeDetails();
			this.getClassDetails();
		},

		watch: {
            socket: {
				handler() {
					this.socket.on("new-message", (data) => {
						
					});
				}
			},
            deep: true,
        },
		methods: {

			async initializeDetails() {
				const activeTab = await localStorage.getItem('activeTab');
				const userDetails = JSON.parse(await localStorage.getItem('user_details'));

				let token = await localStorage.getItem("user_token")

				this.socket = await socketIO.connect("https://api.silabu.com" , {
					transports: ["websocket"],
					auth: { token: `${token}` }        
				});

				console.log(this.socket)
				this.socket.emit('join-class-chat', {"classUuid": this.$route.params.uuid})

				this.userDetails = userDetails;
				this.activeTab = activeTab != null ? activeTab : 'classroom'
				
			},

			async onChangeActiveTab(activeTab) {
				await localStorage.setItem('activeTab', activeTab)
			},

			async getClassDetails() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/classes/list/${this.$route.params.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails = response.data;
						await localStorage.setItem("class_name", response.data.name);
                    }
					
					this.loadingData = false;
					console.log(this.socket)
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 10px;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>