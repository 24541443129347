<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">

		<a-row :gutter="24" class="mt-10">

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24">
				<h4>List of Quizes</h4>
			</a-col>

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-right">
				<a-button id="btn" type="silabu" @click="launchModal" v-if="isCreator">
					<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Quiz
				</a-button>
			</a-col>

		</a-row>
		
		<div v-if="!loadingData && message.length == 0">

			<a-row :gutter="24">

				<a-col class="mb-24" style="height: 100%;" :span="24" :md="8" :lg="8" :xl="8" v-for="qz in quizes" :key="qz.uuid">
					<a-card class=" px-5 h-100">
						<a class="text-dark" style="text-decoration: none !important;" :href="isCreator && `/#/my-classes/view/${classDetails.uuid}/quiz/${qz.uuid}`">
							<a-row :gutter="24">
								<a-col :span="24" :md="24" :lg="24" :xl="24">
									<h6>{{ qz.name }}</h6>
								</a-col>
							</a-row>
							
							<a-row :gutter="24">
								<a-col :span="24" :md="24" :lg="24" :xl="24">
									<p class="text-muted">
										{{ qz.published ? 
											`This quiz was published, ${qz.is_always_available ? 
												'students are allowed to attempt any time they want' : 
												'students are allowed to attempt on a timely basis on ' + moment(qz.start_date).format("DD MMM YYYY") + ` at ${qz.start_time.toString().substring(0,5)}`}`  : 
											"You have not published, publish to start receiving attempts from students."
										}}
									</p>
								</a-col>
							</a-row>
							</a>
							<a-row v-if="isCreator">
								<a-col :span="24" class="text-right mt-2">
									<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditQuiz(qz)"><a-icon type="form" theme="outlined"/> Edit</a-button>
								
									<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(qz.uuid)" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
								</a-col>
							</a-row>
						
					</a-card>
				</a-col>

			</a-row>


		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="text-center" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>

		<div v-if="!loadingData && message.length > 0">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 8, offset: 0}" class="text-center  justify-items-center">

					<label>{{message}}</label>

				</a-col>

			</a-row>
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="quiz.showModal" :footer="null">
			<h4 class="text-center">{{  quiz.isCreate ? 'Add Quiz' : 'Edit Quiz'}}</h4>
			<a-form-model
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Name" :colon="false">
					<a-input 
						v-decorator="['name',
						{ rules: [{ required: true, message: 'Please input your name of the quiz!' }] },
						]" placeholder="Name" 
						v-model="quiz.name"/>
				</a-form-item>
				
				<!-- <a-form-item class="mb-10" label="Number of questions" :colon="false">
					<a-input 
						v-decorator="[
						'question_count',
						{ rules: [{ required: true, message: 'Please input your number of question!' }] },
						]" placeholder="Number of questions" 
						v-model="quiz.question_count"/>
				</a-form-item> -->
				
				<a-form-item class="mb-10" label="Number of questions" :colon="false">
					<a-input 
						type="number"
						v-decorator="[
							'question_count',
							{ rules: [{ required: true, message: 'Please input number of questions!' }] },
						]" 
						placeholder="Number of questions" 
						v-model="quiz.question_count"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Duration (minutes)" :colon="false">
					<a-input 
						type="number"
						v-decorator="[
							'quiz_duration',
							{ rules: [{ required: true, message: 'Please input your quiz duration!' }] },
						]" 
						placeholder="Duration" 
						v-model="quiz.quiz_duration"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Instructions" :colon="false">
					<a-textarea 
						:rows="4"
						v-decorator="['instruction',
							{ rules: [{ required: true, message: 'Please input your name of the quiz!' }] },
						]" 
						placeholder="Instructiomns" 
						v-model="quiz.instruction" />
				</a-form-item>

				
				<a-form-item id="pricing" class="mb-0 pb-0" label="" :colon="false">
					{{ quiz.is_paid ? `Others will be required to pay between ${location.lowestPrice} ${location.currency} and ${location.highestPrice} ${location.currency} to access your quiz` : 'Others will be accessing your quiz for free' }} &nbsp;&nbsp;
					<a-switch class="mt-0 pt-0" v-model="quiz.is_paid" onchange="onPriceToggleChecked"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Price" :colon="false" v-if="quiz.is_paid">
					<a-input 
						type="number"
						v-decorator="[
							'price',
							{ rules: [{ required: true, message: 'Please input your quiz price!' }, {}] },
						]" 
						:min="location.lowestPrice"
						placeholder="Price" 
						v-model="quiz.price"/>
				</a-form-item>

				<a-form-item id="startingTime" class="mb-0 pb-0" label="" :colon="false">
					Allow students to take quiz any time &nbsp;&nbsp;<a-switch class="mt-0 pt-0" v-model="quiz.is_always_available" />
				</a-form-item>

				<a-form-item class="mb-10" label="Start Date" :colon="false" v-if="!quiz.is_always_available">
					<a-date-picker
						v-model="quiz.start_date" 
						format="DD MMM YYYY"
						style="width: 100%"
						v-decorator="[
							'start_date',
							{ rules: [{ required: true, message: 'Please input your start date of the quiz!' }] },
						]" 
						placeholder="Start date" />
				</a-form-item>

				<a-form-item class="mb-10" label="Start Time" :colon="false" v-if="!quiz.is_always_available">
					<a-time-picker 
						class="w-100"
						v-model="quiz.start_time"
						style="width: 100%"
						v-decorator="[
							'start_time',
							{ rules: [{ required: true, message: 'Please input your start time of the quiz!' }] },
						]" 
						format="HH:mm" />
				</a-form-item>

				<a-form-item>
					<a-button type="silabu" id="btn" html-type="submit" class="login-form-button text-white">
						{{ quiz.isCreate ? 'Add Quiz' : 'Edit Quiz' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
                quizes: [],
				form: this.$form.createForm(this, { name: 'quiz_login' }),
				message: '',
				location: {
					lowestPrice: 1000,
					highestPrice: 5000,
					currency: 'TSh'
				},
				quiz: {
					uuid: null,
					name: null,
					instruction: null,
					start_date: this.moment(Date.now()).format("YYYY-MM-DD"),
					start_time: null,
					question_count: null,
					quizableUuid: this.classDetails.uuid,
					quiz_duration: null,
					price: null,
					is_paid: false,
					currency: null,
					quizable_type: "class",
					publish: false,
					is_modern: true,
					published: false,
					is_always_available: true,
					isCreate: true,
					showModal: false,
				}
      		}
		},
		// beforeCreate() {
		// 	// Creates the form and adds to it component's "form" property.
		// 	this.form = this.$form.createForm(this, { name: 'quiz_login' });
		// },
		created() {
			this.getUserLocationDetails()
			this.getQuizes();
		},

		methods: {

			onPriceToggleChecked(checked) {
				if(checked) {
					this.quiz.price = this.location.lowestPrice;
					this.quiz.currency = this.location.currency
				}else {
					this.quiz.price = null;
					this.quiz.currency = null;
				}
			},

			onEditQuiz(item) {
				let startDate = this.moment(item.start_date).format("YYYY-MM-DD");
				this.quiz.uuid = item.uuid;
				this.quiz.name = item.name;
				this.quiz.instruction = item.instruction;
				this.quiz.start_date = this.moment(item.start_date).format("YYYY-MM-DD"),
				this.quiz.start_time = this.moment(startDate + " " + item.start_time).format("YYYY-MM-DD hh:mm");
				this.quiz.question_count = item.question_count;
				this.quiz.quiz_duration = item.quiz_duration;
				this.quiz.quizable_type = "class";
				this.quiz.is_modern = true;
				this.quiz.price = item.price;
				this.quiz.is_paid = item.is_paid;
				this.quiz.currency = item.currency;
				this.quiz.publish = item.published;
				this.quiz.published = item.published;
				this.quiz.quizableUuid = this.classDetails.uuid;
				this.quiz.is_always_available = item.is_always_available;
				this.quiz.isCreate = false;
				this.quiz.showModal = true;

				console.log(this.quiz.start_time)
			},

			launchModal() {
				this.quiz.uuid = null;
				this.quiz.name = null;
				this.quiz.instruction = null;
				this.quiz.start_date = this.moment(Date.now()).format("YYYY-MM-DD");
				this.quiz.start_time = this.moment(Date.now()).format("YYYY-MM-DD hh:mm");
				this.quiz.question_count = null;
				this.quiz.quiz_duration = null;
				this.quiz.quizable_type = "class";
				this.quiz.is_modern = true;
				this.quiz.price = null;
				this.quiz.is_paid = false;
				this.quiz.currency = null;
				this.quiz.publish = false;
				this.quiz.published = false;
				this.quiz.quizableUuid = this.classDetails.uuid;
				this.quiz.is_always_available = true;
				this.quiz.isCreate = true;

				this.quiz.showModal = true;
			},


			async getUserLocationDetails() {

				let locationDetails = JSON.parse(await localStorage.getItem("locationDetails"))

				if(locationDetails != null) {

					this.locationDetails = locationDetails
					this.location.currency = locationDetails.countryCurrencySymbol
					this.location.lowestPrice = 1000 * locationDetails.countryRateToTzs
					this.location.highestPrice = 5000 * locationDetails.countryRateToTzs
					
				}else{

					let token = await localStorage.getItem("user_token")

					let url = `${this.$BACKEND_URL}/geo-location`;

					const config = {
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
					};

					this.$AXIOS.get(url, config).then(async(response) => {

						await localStorage.setItem("locationDetails", JSON.stringify(response.data))

						this.locationDetails = response.data

						this.location.currency = response.data.countryCurrencySymbol
						this.location.lowestPrice = 1000 * response.data.countryRateToTzs
						this.location.highestPrice = 5000 * response.data.countryRateToTzs
						
					}).catch(async(error) => {

						if(error.response && error.response.status == 401) {
							await localStorage.setItem("user_token", null);
							await localStorage.setItem("user_details", null)

							this.$router.push(`/sign-in`);
						}
                	});
				}
            },


			async getQuizes() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quiz/class/${this.classDetails.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						
						if(response.data.length > 0) {
							this.quizes = response.data;

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						if(this.quiz.isCreate) {

							// this.loadingData = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/quiz`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							if(!this.quiz.is_paid) {
								this.quiz.price = null;
								this.quiz.currency = null
							}else{
								this.quiz.currency = 'TSh'
								this.quiz.price = this.quiz.price / this.locationDetails.countryRateToTzs
							}

							this.quiz.start_date = this.moment(this.quiz.start_date).format("YYYY-MM-DD"),
							this.quiz.start_time = this.moment(this.quiz.start_time).format("hh:mm");

							this.$AXIOS.post(url, this.quiz, config).then(async(response) => {
								
								this.quiz.uuid = null,
								this.quiz.name = null,
								this.quiz.instruction = null,
								this.quiz.start_date = this.moment(Date.now()).format("YYYY-MM-DD"),
								this.quiz.start_time = null,
								this.quiz.question_count = null,
								this.quiz.quiz_duration = null,
								this.quiz.is_paid = false;
								this.quiz.quizable_type = "class",
								this.quiz.price = null;
								this.quiz.is_modern = true;
								this.quiz.currency = null;
								this.quiz.publish = false,
								this.quiz.published = false,
								this.quiz.quizableUuid = this.classDetails.uuid,
								this.quiz.is_always_available = true,
								this.quiz.isCreate = true,
								this.quiz.showModal = false,

								this.form.resetFields()

								this.getQuizes()

								// this.loadingData = false;

							}).catch(async(error) => {
								
								// this.loadingData = false;

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{
							// this.loadingData = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/quiz/${this.quiz.uuid}`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.quiz.uuid = undefined;

							if(!this.quiz.is_paid) {
								this.quiz.price = null;
								this.quiz.currency = null
							}else{
								this.quiz.currency = location.currency
								this.quiz.price = this.quiz.price / this.locationDetails.countryRateToTzs
							}

							this.quiz.start_date = this.moment(this.quiz.start_date).format("YYYY-MM-DD"),
							this.quiz.start_time = this.moment(this.quiz.start_time).format("hh:mm");

							this.$AXIOS.patch(url, this.quiz, config).then(async(response) => {
								
								this.quiz.uuid = null,
								this.quiz.name = null,
								this.quiz.instruction = null,
								this.quiz.start_date = this.moment(Date.now()).format("YYYY-MM-DD"),
								this.quiz.start_time = null,
								this.quiz.question_count = null,
								this.quiz.quiz_duration = null,
								this.quiz.is_paid = false;
								this.quiz.quizable_type = "class",
								this.quiz.price = null;
								this.quiz.is_modern = true;
								this.quiz.currency = null;
								this.quiz.publish = false,
								this.quiz.published = false,
								this.quiz.quizableUuid = this.classDetails.uuid,
								this.quiz.is_always_available = true,
								this.quiz.isCreate = true,
								this.quiz.showModal = false,

								this.form.resetFields()

								this.getQuizes()

								// this.loadingData = false;

							}).catch(async(error) => {
								
								// this.loadingData = false;

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}
				})
			},


			async onDelete(uuid) {

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/quiz/${uuid}`;

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

				this.$AXIOS.delete(url, config).then(async(response) => {
					
					this.notify('Quiz was deleted successfully', 'success')
					
					this.getQuizes()

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});

			},

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>